nav.navbar.navbar-expand-lg.navbar-light {
    padding: 5px 0;
}

.nav-link {
    color: #fff !important;
    margin-right: 20px;
    position: relative;
}

.navbar-light .navbar-brand {
    color: #fff !important;
}

.navbar-brand img{
    width: 200px;
}

.all_btn {
    border-radius: 28px;
    background: transparent;
    border: 2px solid #51e8ef;
    color: #fff;
    padding: 5px 25px;
    font-size: 15px;
    letter-spacing: 4px;
    position: relative;
    border-bottom-right-radius: initial;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.all_btn:hover{
    background-color: #51e8ef;
    color: #000;
    transition: all 0.5s ease;
}


.nav-link::after {
    content: '';
    position: absolute;
    background-color: #51e8ef !important;
    color: #51e8ef;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    width: 0%;
    margin: 0 auto;
    opacity: 0;
}

.nav-link:hover::after {
    content: '';
    position: absolute;
    background-color: #51e8ef !important;
    color: #51e8ef;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    width: 100%;
    margin: 0 auto;
}

button.navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url('../../images/nav-icon.png') !important;
    transition: all 0.3s ease-in-out;
    width: 30px;
}


.navbar-toggler .navbar-toggler-icon{
    background-image: url('../../images/close.png') !important;
    width: 20px;
    transition: all 0.3s ease-in-out;
}


.navbar-light .navbar-toggler {
    border: none !important;
    background-color: none !important;
}

@media (max-width: 767px) {
    .navbar-brand img{
        width: 210px;
    }
}