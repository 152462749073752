.services_main {
    margin: 50px 0;
}

.service_section {
    border-radius: 30px;
    background: #0a1e1f;
    padding: 30px 0;
    text-align: center;
    margin-bottom: 30px;
}

.service_section h4 {
    color: #fff;
    font-weight: 600;
}

.service_section img {
    margin-bottom: 30px;
    background-color: #000;
    width: 80px;
    height: 80px;
    object-fit: contain;
    padding: 0 25px;
    border-radius: 100px;
}

.tags span {
    display: inline-block;
    font-size: .7rem;
    color: #51e8ef;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 800;
    letter-spacing: .7px;
    border: 1px solid;
}

.tags {
    margin-top: 20px;
}

.links img {
    width: 22px;
    margin-right: 20px;
    margin-top: 3px;
    height: 22px;
    object-fit: contain;
}

@media (max-width: 767px) {
    .service_row{
        padding: 15px;
    }
}