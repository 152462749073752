.portfolio_main {
    margin: 50px 0;
}

.react-tabs__tab-list {
    border-bottom: none !important;
    display: flex !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
}

.react-tabs__tab--selected {
    border: none !important;
    background: transparent !important;
    color: #51e8ef !important;
    border-bottom: 1px solid #51e8ef !important;
}

.react-tabs__tab {
    color: #fff;
    font-size: 22px;
    margin-right: 15px;
}

.react-tabs__tab:hover {
    color: #51e8ef;
}

.react-tabs__tab:focus:after {
    display: none;
}

.cards-list {
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.card {
    margin: 30px auto;
    border-radius: 40px;
    cursor: pointer;
    transition: 0.4s;
    background: transparent !important;
    height: 220px !important;
}

.card_image {
    max-width: 100%;
    width: 100%;
}

.card_image img {
    object-fit: cover;
    max-width: 100%;
    border-radius: 15px 15px 0 0;
    width: 100%;
}

.card_title {
    position: absolute;
    width: 100%;
    background-color: #0a1e1f;
    padding: 1rem 1.5rem;
    display: flex;
    transition: all 0.5s ease;
    bottom: 0;
    border-radius: 0 0 14px 14px;
    justify-content: space-between;
    align-items: center;
}

.card_title h5{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: 2px;
}

.card_title svg{
    font-size: 30px;
    color: #fff !important;
}

.card:hover {
    transform: scale(0.9);
}

.title-white {
    color: white;
}

.title-black {
    color: black;
}


@media (max-width: 767px) {
    .react-tabs__tab{
        font-size: 23px;
    }
}

@media (max-width: 1024px) {
    .card_title svg{
        font-size: 25px;
    }

    .card_title h5{
        font-size: 16px;
    }
}