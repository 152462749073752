.contact_main {
    background: #060606;
    padding: 50px 0;
}

input.form-control {
    height: 50px;
    background: #000;
    border: 1px solid #333;
    border-radius: 5px;
    margin-bottom: 30px;
    color: #fff !important;
}

input.form-control::placeholder {
    color: #707070;
    font-size: 15px;
}

textarea.form-control {
    background: #000;
    border: 1px solid #333;
    border-radius: 5px;
    margin-bottom: 30px;
    color: #fff !important;
}

textarea.form-control::placeholder {
    color: #707070;
    font-size: 15px;
}

.form-control:focus {
    border: 1px solid #51e8ef !important;
    background: #000 !important;
    box-shadow: none !important;
}

.contact_details h5 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 25px;
}

.contact_details p {
    margin-bottom: 0;
    color: #fff;
}

.contact_details .head {
    color: #51e8ef;
    font-size: 16px;
    margin-bottom: 5px;
}

.social_div {
    position: relative;
}

.links {
    content: '';
    border-radius: 41px;
    padding-right: 20px;
}


@media (max-width: 767px) {
    .links{
        left: -95px;
    }
}