@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #000 !important;
}

img {
    max-width: 100%;
}

a {
    color: #fff;
    text-decoration: none !important;
}

body::-webkit-scrollbar {
    width: 1em;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: #0a1e1f;
    outline: 1px solid #0a1e1f;
}


.pre_loader{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.pre_loader img{
    animation-name: rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 80px;
}

@keyframes rotate {
    from {
        transform: rotate(-360deg);
    }

    to {
        transform: rotate(360deg);
    }
}