.main_about{
    padding: 50px 0;
}

.main_heading{
    text-align: center;
    margin-bottom: 50px;
    color: #51e8ef;
    font-size: 55px;
    font-weight: 900;
}

.about_me h5, .skills h5{
    color: #fff;
    font-size: 25px;
    font-weight: 600;
}

.about_me p{
    color: #fff;
    line-height: 25px;
    font-size: 19px;
}

.about_me ul{
    color: #51e8ef;
    font-size: 19px;
}

.progress_bar{
    color: #fff;
}
.progress_bar p{
    margin-bottom: 1px;
}

.progress_div95, .progress_div80, .progress_div65{
    width: 100%;
    background-color: #0a1e1f;
    height: 5px;
    border-radius: 16px 0;
    position: relative;
}

.progress_div95::before, .progress_div80::before, .progress_div65::before{
    content: '';
    position: absolute;
    width: 95%;
    height: 5px;
    top: 0;
    background-color: #51e8ef;
    border-radius: 16px 0;
}

.progress_div80::before{
    width: 80%;
}

.progress_div65::before{
    width: 65%;
}

@media (max-width: 767px) {
    .main_heading{
        font-size: 35px;
    }

    .about_me h5{
        font-size: 22px;
    }

    .about_me p{
        font-size: 15px;
    }

    .about_me ul{
        font-size: 15px;
    }
}