.main_home{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #fff;
    flex-direction: column;
}

.main_home p{
    background: #0a1e1f;
    border-radius: 32px;
    padding: 5px 20px;
    width: fit-content;
    letter-spacing: 1px;
}

.main_home h1{
    font-size: 55px;
    font-weight: bold;
    text-align: center;
}

.main_home h1 span{
    color: #51e8ef;
}

.main_home h2{
    font-weight: 600;
    font-size: 27px;
}

.started_arrow svg{
   color: #51e8ef !important;
   font-size: 23px;
}

.started_arrow {
    border: 1px solid #51e8ef;
    margin-top: 85px;
    border-radius: 35px;
    padding: 35px 4px 10px 4px;
    animation: up-down 1s ease-in-out infinite alternate-reverse both;
    cursor: pointer;
}

@keyframes up-down{
    0%{
        transform: translateY(5px);
    }
    100%{
        transform: translateY(-5px);
    }
}

@media (max-width: 1024px) {

    .main_home {
        height: 90vh;
    }
    
    .main_home h1 {
        font-size: 25px;
    }

    .main_home p {
        font-size: 14px;
    }

    .main_home h2{
        font-size: 14px;
    }

    .all_btn{
        padding: 5px 15px;
        font-size: 12px;
    }

    .started_arrow{
        margin-top: 160px;
    }
}